$(document).ready(function () {

  $('.accordion-row').accordion({
    "transitionSpeed": 400
  })

  $('.mobile-navbar').click(function () {
    $('.navbar').toggleClass('open')
    $('.mobile-navbar').toggleClass('open')
  })

  let oTable = $('#pricingtable').DataTable({
    "order": [[1, "desc"]],
    "info": false,
    "lengthChange": false,
    "pageLength": 5,
    "language": {
      "sEmptyTable": "Keine Daten in der Tabelle vorhanden",
      "sInfo": "_START_ bis _END_ von _TOTAL_ Einträgen",
      "sInfoEmpty": "Keine Daten vorhanden",
      "sInfoFiltered": "(gefiltert von _MAX_ Einträgen)",
      "sInfoPostFix": "",
      "sInfoThousands": ".",
      "sLengthMenu": "_MENU_ Einträge anzeigen",
      "sLoadingRecords": "Wird geladen ..",
      "sProcessing": "Bitte warten ..",
      "sSearch": "Postleitzahl / Ort / Ortsteil",
      "sZeroRecords": "Keine Einträge vorhanden",
      "oPaginate": {
        "sFirst": "Erste",
        "sPrevious": "Zurück",
        "sNext": "Nächste",
        "sLast": "Letzte"
      },
      "oAria": {
        "sSortAscending": ": aktivieren, um Spalte aufsteigend zu sortieren",
        "sSortDescending": ": aktivieren, um Spalte absteigend zu sortieren"
      },
      "select": {
        "rows": {
          "_": "%d Zeilen ausgewählt",
          "0": "",
          "1": "1 Zeile ausgewählt"
        }
      },
      "buttons": {
        "print": "Drucken",
        "colvis": "Spalten",
        "copy": "Kopieren",
        "copyTitle": "In Zwischenablage kopieren",
        "copyKeys": "Taste <i>ctrl</i> oder <i>\u2318</i> + <i>C</i> um Tabelle<br>in Zwischenspeicher zu kopieren.<br><br>Um abzubrechen die Nachricht anklicken oder Escape drücken.",
        "copySuccess": {
          "_": "%d Spalten kopiert",
          "1": "1 Spalte kopiert"
        }
      }
    }
  });

  $('#searchfield').on('keyup change', function () {
    $('#pricingtable tbody tr').find("[type=checkbox]").prop("checked", false);
    oTable.search($(this).val()).draw();
    $('#pricing-modal').show();
    $('#pricingtable_filter input[type=search]').focus();
  });

  $('#pricingtable_filter input[type=search]').on('keyup change', function () {
    $('#searchfield').val($(this).val());
  });

  $('#close').on('click', function () {
    $('#pricing-modal').hide();
  });

  let url = String(location.href);

  let plz = getQueryString("plz");

  if (plz) {
    $("#searchfield").val(plz);
    oTable.search($("#searchfield").val()).draw();
  }

  $('td').filter(function () {
    if (this.textContent.trim() === plz) {
      get_and_set_vals($(this).parents("tr"));
      $("form").show();
    }
  });

  function get_and_set_vals(that) {

    let place = $(that).find("td:nth-child(2)").text();
    let pc = $(that).find("td:nth-child(4)").text();
    let entsorger = $(that).find("td:nth-child(5)").text();
    let mail = $(that).find("td:nth-child(6)").text();
    let price_80 = $(that).find("td:nth-child(7)").text();
    let price_120 = $(that).find("td:nth-child(8)").text();
    let price_240 = $(that).find("td:nth-child(9)").text();
    let gg_80 = $(that).find("td:nth-child(10)").text();
    let gg_120 = $(that).find("td:nth-child(11)").text();
    let gg_240 = $(that).find("td:nth-child(12)").text();
    let ko_stell = $(that).find("td:nth-child(13)").text();
    let miete_80 = $(that).find("td:nth-child(14)").text();
    let miete_120 = $(that).find("td:nth-child(15)").text();
    let miete_240 = $(that).find("td:nth-child(16)").text();

    $("#pricing-info").addClass(entsorger);

    $("input[name=mailto]").val(mail);
    $("input[name=user_city]").val(place);
    $("input[name=user_plz]").val(pc);

    let html = "<h3>Wir können Ihnen folgende Konditionen anbieten:</h3>";

    html += "<div class='columns'><div class='column'>Ort: " + pc + ", " + place + "<br><br>";

    if (price_80) {

      $("#prod1").parents("li").show();

      html += "<li>80L Tonne: " + price_80 + "€ Abholung";
      if (gg_80) { html += ", Grundgebühr: " + gg_80 + "€ / Monat"; }
      html += "</li>";
    }

    if (price_120) {

      $("#prod2").parents("li").show();

      html += "<li>120L Tonne: " + price_120 + "€ Abholung";
      if (gg_120) { html += ", Grundgebühr: " + gg_120 + "€ / Monat"; }
      html += "</li>";
    }

    if (price_240) {

      $("#prod3").parents("li").show();


      html += "<li>240L Tonne: " + price_240 + "€ Abholung";
      if (gg_240) { html += ", Grundgebühr: " + gg_240 + "€ / Monat"; }
      html += "</li>";
    }

    if (ko_stell) {
      html += "<li>Stellkosten: " + ko_stell + "€ (einmalig)</li>";
    }

    if (miete_80) {
      html += "<li>monatliche Miete: " + miete_80 + "€</li>";
    }

    if (miete_120) {
      html += "<li>monatliche Miete: " + miete_120 + "€</li>";
    }

    if (miete_240) {
      html += "<li>monatliche Miete: " + miete_240 + "€</li>";
    }

    if (!price_80 && !price_120 && !price_240) {
      html += "<li>im Moment kein Preis verfügbar</li>";
    } else {
      $("#size").show();
    }

    html += "</ul><br></div>"

    let url = String(location.href);

    if (url.indexOf("preise.html" && (price_80 || price_120 || price_240))) {
      html += '<div class="column"><div class="cta-wrapper cta-btn block" style="margin-top:5rem"><a href="/biotonnebestellen.html?plz=' + pc + '">Angebot anfordern<i class="icon__angleright-w"></i></a></div></div></div>';
    }

    $("#pricing-info-inner").html(html);
    $("#pricing-info").show();
    $(".section__order .tablelabel").show();
    $("#pricing-reset").show();

    $('#pricing-modal').hide();
    $('#searchfield, #searchinput').hide();
  }

  $('#pricingtable').on('click', 'tr', function (e) {
    e.preventDefault();

    let price_80 = $(this).find("td:nth-child(7)").text();
    let price_120 = $(this).find("td:nth-child(8)").text();
    let price_240 = $(this).find("td:nth-child(9)").text();

    console.log(price_80);

    $('#pricingtable tbody tr').find("[type=checkbox]").prop("checked", false);
    $(this).find("[type=checkbox]").prop("checked", true);

    if (price_80 || price_120 || price_240) {
      $("form").show();
    }

    get_and_set_vals(this);

  })

  $('#pricing-reset').on("click", function (e) {
    e.preventDefault();

    $("#pricing-info").hide();
    $("#pricing-reset").hide();
    $("form").hide();
    $('#searchfield').val("").show();
    $("#pricing-info-inner").html("");
    $(".inputs").show();
    $(".section__order .tablelabel").hide();

    $('.productgroup li').hide();

    $("#pricing-info").removeClass();

  });
})

function validateForm() {
  const radios2 = document.getElementsByName("product")
  var form2IsValid = false

  var i = 0
  var j = 0
  while (!form1IsValid && i < radios1.length) {
    if (radios1[i].checked) form1IsValid = true
    i++
  }

  while (!form2IsValid && j < radios2.length) {
    if (radios2[j].checked) form2IsValid = true
    j++
  }

  if (!form1IsValid || !form2IsValid) {
    $('.errormessage').toggleClass('hidden');
    $('html, body').animate({
      scrollTop: $('.pagetitle').offset().top + 'px'
    }, 'fast')
    return false
  }
  return true
}

function getQueryString() {
  var key = false, res = {}, itm = null;
  // get the query string without the ?
  var qs = location.search.substring(1);
  // check for the key as an argument
  if (arguments.length > 0 && arguments[0].length > 1)
    key = arguments[0];
  // make a regex pattern to grab key/value
  var pattern = /([^&=]+)=([^&]*)/g;
  // loop the items in the query string, either
  // find a match to the argument, or build an object
  // with key/value pairs
  while (itm = pattern.exec(qs)) {
    if (key !== false && decodeURIComponent(itm[1]) === key)
      return decodeURIComponent(itm[2]);
    else if (key === false)
      res[decodeURIComponent(itm[1])] = decodeURIComponent(itm[2]);
  }

  return key === false ? res : null;
}